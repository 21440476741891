<script>
import map from 'lodash/map'
import pick from 'lodash/pick'

export default {
  computed: {
    ssrPayloadRules() {
      return {
        accessoryProducts: 'product',
        alternativeProducts: 'product',
        bestsellers: 'product',
        products: 'product',
        mostSoldProducts: 'product',
        newestProducts: 'product',
        relatedProducts: 'product',
      }
    },
    ssrPayloadPickers() {
      return {
        product: [
          'availability.availability',
          'availability.delayDays',
          'availability.isAvailable',
          'availability.soldAvailability',
          'availability.stock',
          'brand.title',
          'categoryUrl',
          'categoryName',
          'content.created',
          'content.date_of_storage',
          'content.product_box_hover_image',
          'defaultVariantId',
          'discounts',
          'freeShippers',
          'gifts',
          'hasVariants',
          'id',
          'mainImage',
          'name',
          'prices',
          'perex',
          'rating',
          'slug',
          'tags',
          'variants',
          'visibility',
        ],
        productVariant: [
          'availability.availability',
          'availability.isAvailable',
          'availability.amountMin',
          'availability.delayDays',
          'availability.soldAvailability',
          'availability.stock',
          'content.parameters',
          'content.parameters',
          'defaultPrices.originalWithTax',
          'fullname',
          'id',
          'mainImage',
          'marketingCode',
          'name',
          'prices',
          'unit',
          'visibility',
        ],
      }
    },
  },
  methods: {
    /**
     * Filter and modify keys from the provided payload based on the given rules and pickers.
     *
     * @param {Object} options - The options for filtering the payload.
     * @param {Object} options.payload - The payload to filter.
     * @param {Object} options.rules - The rules to use for filtering. Each key represents a payload key, and the corresponding value represents the type of filtering to apply.
     * @param {Object} options.pickers - The pickers to use for filtering. Each key represents a type of filtering, and the corresponding value represents the keys to pick from the payload.
     */
    pickKeysFromPayload({ payload, rules, pickers }) {
      const payloadCopy = { ...payload }

      for (const key in rules) {
        if (!(key in payloadCopy)) continue
        const type = rules[key]

        if (type === 'product') {
          payloadCopy[key] = map(payloadCopy[key], product => {
            const item = pick(product, pickers.product)
            item.variants = map(item.variants, variant => pick(variant, pickers.productVariant))
            return item
          })
        } else {
          payloadCopy[key] = map(payloadCopy[key], item => {
            return pick(item, pickers[type])
          })
        }
      }

      return payloadCopy
    },
  },
}
</script>
